import { IStore, StoreContext } from 'context/store';
import { useContext } from 'react';
import { useSyncExternalStore } from 'use-sync-external-store/shim';

export function useStore<T>(
    selector: (store: IStore) => T,
): [T, (selector: (store: IStore) => Partial<IStore>) => void] {
    const store = useContext(StoreContext);
    if (!store) {
        throw new Error('Store not found');
    }

    const state = useSyncExternalStore(
        store.subscribe,
        () => selector(store.getter()),
        () => selector(store.getter()),
    );

    return [state, store.setter];
}
