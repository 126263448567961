import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { Experiments, getSerializeToggles, ISerializedToggles } from 'common/api/ab';
import { logger } from '@indriver/elastic-logger';
import { sendAnalyticsEvent } from 'shared/lib/send-analytics-event';
import { readCookiesWithRetry } from 'shared/lib/read-cookies-with-retry';
import { GeoWrapper } from 'context/geo';
import { generateString, ITollsObj } from 'shared/lib/generate-string';
import { useMe } from 'context/user';
import * as Sentry from '@sentry/nextjs';

export const ABTogglesContext = createContext<IABTogglesContext>({
    toggles: { experiments: {}, isLoading: true },
    fetchToggles: async () => {
        return undefined;
    },
});

const MAIN_REDESIGN_COUNTRIES_AVAILABLE = [30, 15, 153, 12, 22, 30, 14, 60, 11];

interface IABTogglesContext {
    toggles: ISerializedToggles;
    fetchToggles: (namespaces?: string[] | undefined) => Promise<ISerializedToggles | undefined>;
}

interface IABTogglesProviderProps {
    children: ReactNode;
}

export const ABTogglesProvider: FC<IABTogglesProviderProps> = (props) => {
    const [toggles, setToggles] = useState({ experiments: {}, isLoading: true });
    const geo = useContext(GeoWrapper);
    const { userInfo } = useMe();

    const fetchToggles = useCallback(
        async (namespaces = ['app_start', 'intercity3']) => {
            try {
                const gaId = await readCookiesWithRetry('_ga', 10);
                setToggles({ experiments: {}, isLoading: true });
                if (gaId && geo?.id) {
                    const resAB = await getSerializeToggles({
                        gaId,
                        countryId: geo.id,
                        namespaces,
                        userId: userInfo?.id,
                    });

                    logger.info({ resAB });
                    setToggles({ experiments: { ...toggles.experiments, ...resAB.experiments }, isLoading: false });
                    return resAB;
                }
                setToggles({ experiments: { ...toggles.experiments }, isLoading: false });
                return undefined;
            } catch (e) {
                Sentry.captureException(e);
                logger.info({ e });
                setToggles({ experiments: { ...toggles.experiments }, isLoading: false });
                return undefined;
            }
        },
        [geo, userInfo],
    );

    useEffect(() => {
        (async () => {
            if (userInfo?.id) {
                await fetchToggles();
            }
        })()
            .then((r) => r)
            .catch((e) => Sentry.captureException(e));
    }, [userInfo?.id]);

    useEffect(() => {
        (async () => {
            try {
                const gaId = await readCookiesWithRetry('_ga', 10);
                if (gaId) {
                    const resAB = await fetchToggles();
                    const experiment = resAB?.experiments?.[Experiments.INTERCITY3_WEB_POPULAR_ROUTES];
                    const cpfExperiment = resAB?.experiments?.[Experiments.INTERCITY3_WEB_CPF_FORM];
                    const redesignExperiment = resAB?.experiments?.[Experiments.INTERCITY3_WEB_REDESIGN_MAIN];
                    const addressExperiment = resAB?.experiments?.[Experiments.INTERCITY3_WEB_NEW_ADDRESS_FIELDS];
                    // const chatExperiment = resAB?.experiments?.[Experiments.INTERCITY3_WEB_CHAT];
                    const tollsExperiment = resAB?.experiments?.[Experiments.INTERCITY3_WEB_TOLLS];
                    const redesignFormExperiment = resAB?.experiments?.[Experiments.INTERCITY3_WEB_REDESIGN_FORM];

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const tollsGroupType = tollsExperiment ? generateString(tollsExperiment as ITollsObj) : 'control';

                    sendAnalyticsEvent('web.intercity.psg.tolls.group_ab', {
                        device_id: gaId,
                        type: tollsGroupType,
                    });

                    const type = experiment?.enabled
                        ? experiment?.large
                            ? 'large'
                            : experiment?.medium
                            ? 'medium'
                            : 'small'
                        : 'control';
                    sendAnalyticsEvent('web.intercity.psg.popular_route.group_ab', { device_id: gaId, type });
                    geo?.code === 'BR' &&
                        sendAnalyticsEvent('web.intercity.psg.cpf.group_ab', {
                            device_id: gaId,
                            type: cpfExperiment?.enabled ? 'cpf_enabled' : 'control',
                        });

                    geo?.code === 'IN' &&
                        sendAnalyticsEvent('web_intercity_psg_round_trip_group_ab', {
                            device_id: gaId,
                            type: geo?.code === 'IN' ? 'enabled' : 'disabled',
                        });

                    if (addressExperiment) {
                        sendAnalyticsEvent('web.intercity.psg.share_loc.group_ab', {
                            device_id: gaId,
                            type: addressExperiment?.enabled ? 'enabled' : 'disabled',
                        });
                    }

                    const redesignGroup = redesignExperiment?.downloadblock
                        ? redesignExperiment?.cookieblock
                            ? 'all_disabled'
                            : 'download_disabled'
                        : redesignExperiment?.cookieblock
                        ? 'cookie_disabled'
                        : 'all_enabled';

                    geo?.id &&
                        MAIN_REDESIGN_COUNTRIES_AVAILABLE.includes(geo?.id) &&
                        sendAnalyticsEvent('web.intercity.psg.main_redesign.group_ab', {
                            device_id: gaId,
                            type: redesignGroup,
                        });

                    if (redesignFormExperiment) {
                        sendAnalyticsEvent('web.intercity.psg.form_redesign.group_ab', {
                            device_id: gaId,
                            type: redesignFormExperiment?.enabled ? 'enabled' : 'control',
                        });
                    }
                }
            } catch (e) {
                Sentry.captureException(e);
                logger.error('AB_TOGGLES_USE_EFFECT', { e });
            }
        })()
            .then((r) => r)
            .catch((e) => Sentry.captureException(e));
    }, [geo?.id]);

    return <ABTogglesContext.Provider value={{ toggles, fetchToggles }}>{props.children}</ABTogglesContext.Provider>;
};
