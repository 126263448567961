const COUNTRY_CAPITALS = {
    AF: '',
    AL: '',
    DZ: 39875,
    AS: 8376,
    AD: '',
    AO: 12640,
    AG: 7545,
    AR: 5483,
    AM: 635,
    AU: 5336,
    AT: '',
    AZ: 27318,
    BS: 6234,
    BH: 4203,
    BD: 5718,
    BB: 6226,
    BY: '',
    BE: 4215,
    BZ: 7527,
    BJ: 13619,
    BT: 19461,
    BO: 4689,
    BA: '',
    BW: 5353,
    BR: 4530,
    BN: 8486,
    BG: 22675,
    BF: 6288,
    BI: '',
    CV: 4762,
    KH: 8541,
    CM: 5355,
    CA: 4423,
    CF: '',
    TD: 13289,
    CL: 8178,
    CN: 17953,
    CO: 4197,
    KM: 13105,
    CG: 10062,
    CD: 12383,
    CR: 24090,
    HR: '',
    CU: '',
    CY: 31789,
    CZ: 4212,
    DK: '',
    DJ: 10480,
    DM: 7705,
    DO: 4382,
    EC: 4404,
    EG: 10155,
    SV: 4195,
    GQ: 12752,
    ER: 6416,
    EE: '',
    SZ: 12913,
    ET: 5799,
    FJ: 17502,
    FI: '',
    FR: 20565,
    GA: 6417,
    GM: 9742,
    GE: 5221,
    DE: '',
    GH: 5365,
    GR: '',
    GD: 7928,
    GT: 4198,
    GN: 6289,
    GW: 6408,
    GY: 6230,
    HT: 6169,
    HN: 5536,
    HU: '',
    IS: '',
    IN: 5233,
    ID: 5676,
    IR: '',
    IQ: 27291,
    IE: '',
    IL: '',
    IT: 20500,
    CI: 11779,
    JM: 5368,
    JP: '',
    JO: 9469,
    KZ: 150,
    KE: 4205,
    KI: '',
    KP: '',
    KR: '',
    KW: 12534,
    KG: 639,
    LA: 6267,
    LV: 7539,
    LB: 9823,
    LS: 5369,
    LR: 5370,
    LY: 11608,
    LI: '',
    LT: '',
    LU: '',
    MG: 6411,
    MW: 5371,
    MY: 5910,
    MV: 6653,
    ML: '',
    MT: '',
    MH: '',
    MR: 13378,
    MU: 5987,
    MX: 4143,
    FM: 8719,
    MD: '',
    MC: '',
    MN: '',
    ME: '',
    MA: 4208,
    MZ: 12831,
    MM: 8745,
    NA: 5373,
    NR: '',
    NP: 5957,
    NL: 7953,
    NZ: 4796,
    NI: 5543,
    NE: 6412,
    NG: 4342,
    MK: '',
    NO: '',
    OM: 31705,
    PK: 5448,
    PW: '',
    PA: '',
    PG: 5398,
    PY: 5550,
    PE: 36603,
    PH: 5421,
    PL: '',
    PT: '',
    QA: 4202,
    RO: '',
    RU: 634,
    RW: 5454,
    KN: 19474,
    LC: 6225,
    VC: '',
    WS: 19477,
    SM: '',
    ST: 11109,
    SA: 13755,
    SN: 6278,
    RS: '',
    SC: 20706,
    SL: 5453,
    SG: 5455,
    SK: '',
    SI: '',
    SB: 8929,
    SO: '',
    ZA: 807,
    SS: 5457,
    ES: 4224,
    LK: 8996,
    SD: '',
    SR: 6237,
    SE: '',
    CH: 5603,
    SY: '',
    TW: 9027,
    TJ: 4262,
    TZ: 829,
    TH: 850,
    TL: 9150,
    TG: 6414,
    TO: 9066,
    TT: 6229,
    TN: 4210,
    TR: 5825,
    TM: 9579,
    TV: '',
    UG: 5467,
    UA: 762,
    AE: 9129,
    GB: 836,
    US: 21450,
    UY: 5552,
    UZ: 638,
    VU: 9087,
    VA: '',
    VE: '',
    VN: 5754,
    YE: '',
    ZM: 5471,
    ZW: 5473,
};

export default COUNTRY_CAPITALS;
