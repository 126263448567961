import Head from 'next/head';
import NextApp, { AppContext, AppProps } from 'next/app';
import React, { useState } from 'react';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { App } from 'app/app';
import 'normalize.css';
import '@indriver/yrel/dist/fonts/noto.css';
import '@indriver/yrel/dist/theme-dark/theme.css';
import '@indriver/yrel/dist/theme-light/theme.css';
import { StoreProvider } from 'context/store';
import Snackbar from 'shared/ui/snackbar';
import 'intl-pluralrules';
import { getNovaTranslations, IntlProviderNext } from '@indriver/nova';
import useTranslateComponents from 'shared/hooks/useTranslateComponents';
import { urPK } from 'data/locales/locale';
import { INovaDictionaryWithLang } from '@indriver/nova/dist/types/intl/types';
import { logger } from '@indriver/elastic-logger';
const datePickerDictKeys = ['title-date', 'title-day', 'title-time', 'next', 'done', 'yesterday', 'today', 'tomorrow', 'day-label', 'year-label', 'month-label', 'hours-label', 'minutes-label', 'timezone-label'];
interface IWebVitalsMetric {
  label: string;
  value: number;
}
export function reportWebVitals(metric: IWebVitalsMetric) {
  metric.label === 'web-vital' && logger.info(JSON.stringify(metric, null, 2));
}
function MyApp({
  Component,
  pageProps
}: AppProps) {
  const [queryClient] = useState(() => new QueryClient());
  const {
    t,
    i18n: {
      language
    }
  } = useTranslation();
  const dict = useTranslateComponents(language, datePickerDictKeys);
  const dictionary: INovaDictionaryWithLang = {
    [language]: {
      DatePickerMobile: dict
    }
  };
  dictionary['ur-PK'] = {
    ...dictionary['ur-PK'],
    dateFns: urPK
  };
  return <QueryClientProvider client={queryClient}>
            <Head>
                <meta charSet='utf-8' />
                <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
                <meta name='description' content={t('landing.page_description')} />
                <title>{t('landing.page_title')}</title>
                <meta name='viewport' content='minimum-scale=1, maximum-scale=5, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover' />
                <meta name='mobile-web-app-capable' content='yes' />
                <meta name='apple-mobile-web-app-capable' content='yes' />
                <meta name='application-name' content='inDrive' />
                <meta name='apple-mobile-web-app-title' content='inDrive' />
                <meta name='theme-color' content='#fff' />
                <meta name='msapplication-navbutton-color' content='#fff' />
                <meta name='apple-mobile-web-app-status-bar-style' content='black-translucent' />
                <meta name='msapplication-starturl' content='/' />
            </Head>
            <StoreProvider>
                <IntlProviderNext dictionary={dictionary} locale={language}>
                    <App>
                        <Component {...pageProps} />
                    </App>
                    <div id='portal-overlay' style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          insetInlineEnd: 0,
          insetInlineStart: 0,
          pointerEvents: 'none'
        }} />
                    <div id='mobile-city-root' style={{
          position: 'absolute',
          zIndex: 3
          // top: 0,
          // bottom: 0,
          // right: 0,
          // left: 0,
          // pointerEvents: 'none',
        }} />
                    <div id='modal-root' />
                    <Snackbar />
                </IntlProviderNext>
            </StoreProvider>
        </QueryClientProvider>;
}
MyApp.getInitialProps = async function (appCtx: AppContext) {
  await NextApp.getInitialProps(appCtx);
  return {
    pageProps: {
      ...getNovaTranslations()
    }
  };
};

// export async function getServerSideProps() {
//     return {
//         props: {
//             customApp: true,
//         },
//     };
// }

export default appWithTranslation(MyApp);