import axios from 'axios';
import { apiInstance } from 'common/api/base';
import { ICloudflareResponse, ICountry, ICountryWithCode } from 'common/api/models';
import { CountryType } from '@indriver/nova-core';
import { logger } from '@indriver/elastic-logger';
import * as Sentry from '@sentry/nextjs';

export const getCountryCloudflare = async (): Promise<ICloudflareResponse | null> => {
    try {
        const response = await axios.get<string>('https://1.1.1.1/cdn-cgi/trace');

        const arr = response.data
            ?.trim()
            .split('\n')
            .map((e) => e.split('='));
        return Object.fromEntries(arr);
    } catch (e) {
        Sentry.captureException(e);
        logger.error({ e });
        return null;
    }
};

export const getCountryByCode = async (code: string): Promise<ICountryWithCode | null> => {
    try {
        const response = await apiInstance.get<ICountry>('/web/v1/info/country_short', {
            params: {
                code,
            },
        });
        return { ...response.data, code: code as CountryType };
    } catch (e) {
        Sentry.captureException(e);
        logger.error({ e });
        return null;
    }
};
