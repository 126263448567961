import { AnimatePresence, LazyMotion, m } from 'framer-motion';
import { FC, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useMounted } from 'shared/hooks/useMounted';
import { useSnacks } from 'shared/hooks/useSnacks';
import { SnackbarItemMemo } from './snackbar-item';
import { SnackbarContainer } from './snackbar.styles';

const loadFeatures = async () => import('./snackbar-features').then((res) => res.default);

const Snackbar: FC = () => {
    const { snacks } = useSnacks();
    const mounted = useMounted();
    const containerRef = useRef<HTMLDivElement>(null);

    if (!mounted) {
        return null;
    }

    return createPortal(
        <SnackbarContainer ref={containerRef} class='snack-container'>
            <LazyMotion features={loadFeatures}>
                <AnimatePresence initial={false}>
                    {snacks.map((snack) => (
                        <m.div
                            key={snack.id}
                            layout
                            initial={{ opacity: 0, y: '50%' }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}>
                            <SnackbarItemMemo snack={snack} />
                        </m.div>
                    ))}
                </AnimatePresence>
            </LazyMotion>
        </SnackbarContainer>,
        window.document.getElementById('portal-overlay') as Element,
    );
};

export default Snackbar;
