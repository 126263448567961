import { ReactNode, useCallback, useRef, createContext } from 'react';
import { ISnack } from 'shared/hooks/useSnacks';

export interface IStore {
    snacks: ISnack[];
}

export interface IStoreContext {
    getter: () => IStore;
    setter: (selector: (store: IStore) => Partial<IStore>) => void;
    subscribe: (cb: () => void) => () => void;
}

export const StoreContext = createContext<IStoreContext>({} as IStoreContext);

export const StoreProvider = ({ children }: { children: ReactNode }) => {
    const store = useRef({ snacks: [] } as IStore);

    const subscribers = useRef(new Set<() => void>());

    const getter = useCallback(() => store.current, []);

    const setter = useCallback((selector: (store: IStore) => Partial<IStore>) => {
        const value = selector(store.current);

        store.current = {
            ...store.current,
            ...value,
        };
        subscribers.current.forEach((cb) => cb());
    }, []);

    const subscribe = useCallback((cb: () => void) => {
        subscribers.current.add(cb);
        return () => subscribers.current.delete(cb);
    }, []);

    const value = {
        getter,
        setter,
        subscribe,
    };

    return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};
