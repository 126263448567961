import React, { FC, ReactElement, useEffect, useState } from 'react';
import { GeoWrapper } from 'context/geo';
import { useTranslation } from 'next-i18next';
import { resolveLang } from 'shared/lib/date-fns-i18n';
import dynamic from 'next/dynamic';
import { ThemeProvider } from '@indriver/nova';
import { ICloudflareResponse, ICountryWithCode } from 'common/api';
import { ABTogglesProvider } from 'context/ab-toggles';
import { getCountryByCode, getCountryCloudflare } from 'common/api/dictionaries';
import { LocationProvider } from 'context/location';
import { logger } from '@indriver/elastic-logger';
import { UserProvider } from 'context/user';
import { PreviousURLProvider } from 'context/prevRoute';
import { setItemToStorage } from 'shared/lib/storage';
import COUNTRY_CAPITALS from 'shared/constants/cities';
import * as Sentry from '@sentry/nextjs';
import { Body } from './app.styles';

export const App: FC<{
    children: ReactElement;
}> = ({ children }) => {
    const [geo, setGeo] = useState<ICountryWithCode | null>(null);
    const { i18n } = useTranslation();

    useEffect(() => {
        const setLocale = async () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const registerLocale = dynamic(async () =>
                import('react-datepicker').then((module) => module.registerLocale),
            );
            // eslint-disable-next-line @typescript-eslint/await-thenable
            const locale = await resolveLang(i18n.resolvedLanguage);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            registerLocale(i18n.resolvedLanguage, locale);
        };

        setLocale()
            .then((r) => r)
            .catch((e) => Sentry.captureException(e));
    }, [i18n.resolvedLanguage]);

    useEffect(() => {
        // getCountry()
        //     .then((res) => setGeo(res.nearCountry.code ? res.nearCountry : null))
        //     .catch(() => {});
        (async () => {
            const res = (await getCountryCloudflare()) as ICloudflareResponse;
            if (res?.loc) {
                const countryInfo = await getCountryByCode(res.loc);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                COUNTRY_CAPITALS[res.loc] && setItemToStorage('X-City-Id', COUNTRY_CAPITALS?.[res.loc]);

                setGeo(countryInfo?.code ? countryInfo : null);
            } else {
                setGeo(null);
            }
        })()
            .then((r) => r)
            .catch((e) => Sentry.captureException(e));
        setTimeout(() => {
            try {
                if (window) {
                    window?.AF_SMART_SCRIPT?.fireImpressionsLink();
                }
            } catch (e) {
                logger.error({ e }, 'fire-impressions-link');
            }
        }, 5000);
    }, []);

    return (
        // TODO: контрибьют в дизайн систему пропса children themeProvider
        <GeoWrapper.Provider value={geo}>
            <UserProvider>
                <ABTogglesProvider>
                    <LocationProvider>
                        <PreviousURLProvider>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <ThemeProvider value='theme-light' autoDetectTheme={false}>
                                <Body>{children}</Body>
                            </ThemeProvider>
                        </PreviousURLProvider>
                    </LocationProvider>
                </ABTogglesProvider>
            </UserProvider>
        </GeoWrapper.Provider>
    );
};
