import type { MatchFn, MatchValueCallback } from '../../types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface BuildMatchPatternFnArgs<Result> {
    matchPattern: RegExp;
    parsePattern: RegExp;
    valueCallback?: MatchValueCallback<string, Result>;
}

export default function buildMatchPatternFn<Result>(args: BuildMatchPatternFnArgs<Result>): MatchFn<Result> {
    return (string, options = {}) => {
        const matchResult = args.matchPattern.exec(string);
        if (!matchResult) return null;
        const matchedString = matchResult[0];

        const parseResult = args.parsePattern.exec(string);
        if (!parseResult) return null;
        let value = (args.valueCallback ? args.valueCallback(parseResult[0]) : parseResult[0]) as Result;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value = options.valueCallback ? options.valueCallback(value) : value;

        const rest = string.slice(matchedString.length);

        return { value, rest };
    };
}
