import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { getCookie, setCookie } from 'cookies-next';
import { sendAnalyticsEvent } from 'shared/lib/send-analytics-event';

export interface ILocation {
    latitude: number | null;
    longitude: number | null;
    error: GeolocationPositionError | string | null;
}

interface ILocationContextProps {
    children: ReactNode;
}

type LocationHookResult = [ILocation, () => void];

const LocationContext = createContext<LocationHookResult | null>(null);

export const LocationProvider: FC<ILocationContextProps> = ({ children }) => {
    const initialCoordsFromCookies = getCookie('intercity_coords');
    const initialCoords =
        typeof initialCoordsFromCookies === 'string'
            ? JSON.parse(initialCoordsFromCookies)
            : { latitude: null, longitude: null };

    const [userLocation, setUserLocation] = useState<ILocation>({
        ...initialCoords,
        error: null,
    });

    const updateCookie = (coords: ILocation) => {
        const expires = new Date();
        expires.setDate(expires.getDate() + 30); // истекает через 30 дней

        setCookie('intercity_coords', JSON.stringify(coords), { expires }); // сохранить куки на 7 дней
    };
    const requestGeoLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    sendAnalyticsEvent('web.intercity.allow_location.done');
                    const coords = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        error: null,
                    };

                    setUserLocation(coords);

                    updateCookie(coords);
                },
                (error) => {
                    sendAnalyticsEvent('web.intercity.allow_location.error');
                    setUserLocation({
                        latitude: null,
                        longitude: null,
                        error: error,
                    });
                },
            );
        } else {
            setUserLocation({
                latitude: null,
                longitude: null,
                error: 'Геолокация не поддерживается данным браузером.',
            });
        }
    };

    useEffect(() => {
        if (userLocation.latitude || userLocation.longitude) {
            requestGeoLocation();
        }
    }, []); // вызываем запрос только если координаты установлены из кук

    const locationHookResult: LocationHookResult = [userLocation, requestGeoLocation];

    return <LocationContext.Provider value={locationHookResult}>{children}</LocationContext.Provider>;
};

export const useLocation = (): LocationHookResult => {
    const context = useContext(LocationContext);
    if (context === null) {
        throw new Error('useGeoLocation must be used within a GeoLocationProvider');
    }
    return context;
};
