import { createContext, FC, ReactNode, useContext, useState } from 'react';

interface IPrevURLContext {
    previousURL: string;
    setPrevious: (url: string) => void;
}

export const PreviousURLContext = createContext<IPrevURLContext | null>(null);

export const usePreviousURL = () => useContext(PreviousURLContext);

interface IPrevRouterProps {
    children?: ReactNode;
}

export const PreviousURLProvider: FC<IPrevRouterProps> = ({ children }) => {
    const [previousURL, setPreviousURL] = useState<string>('');

    const setPrevious = (url: string) => {
        setPreviousURL(url);
    };

    return <PreviousURLContext.Provider value={{ previousURL, setPrevious }}>{children}</PreviousURLContext.Provider>;
};
