import { getCookie } from 'cookies-next';

const waitFor = async (secs = 1000) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve('');
        }, secs);
    });
};
export const readCookiesWithRetry = async (key: string, retry = 3): Promise<string | undefined> => {
    const value = getCookie(key) as string;

    if (value) {
        return value;
    }

    if (retry === 1) {
        return undefined;
    }

    await waitFor(1000);

    return readCookiesWithRetry(key, retry - 1);
};
