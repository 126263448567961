import { useCallback } from 'react';
import { useStore } from './useStore';

export enum EnumSnackType {
    info = 'info',
    success = 'success',
    warning = 'warning',
    failure = 'failure',
}
export interface ISnack {
    id: number;
    type: EnumSnackType;
    message: string;
}

export const useSnacks = () => {
    const [snacks, setStore] = useStore((store) => store.snacks);

    const removeSnack = useCallback(
        (id: number): void => {
            setStore((store) => ({ snacks: store.snacks.filter((snack) => snack.id !== id) }));
        },
        [setStore],
    );

    const addSnack = useCallback(
        (message: string, type: EnumSnackType = EnumSnackType.info, delay = 10000) => {
            const id = new Date().getTime() + Math.random();

            setStore((store) => ({
                snacks: [
                    ...store.snacks,
                    {
                        id,
                        message,
                        type,
                    },
                ],
            }));

            setTimeout(() => removeSnack(id), delay);
        },
        [removeSnack, setStore],
    );

    return {
        snacks,
        addSnack,
        removeSnack,
    };
};
