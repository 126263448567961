import formatDistance from './_lib/formatDistance/index';
import formatLong from './_lib/formatLong/index';
import formatRelative from './_lib/formatRelative/index';
import localize from './_lib/localize/index';
import match from './_lib/match/index';
import type { Locale } from '../types';

/**
 * @type {Locale}
 * @category Locales
 * @summary Urdu locale (Pakistan).
 * @language Urdu
 * @iso-639-2 urd
 * @author Alexandr Dikin [@aleksandrDik]{@link https://github.com/aleksandrDik}
 */
const locale: Locale = {
    code: 'ur-PK',
    formatDistance: formatDistance,
    formatLong: formatLong,
    formatRelative: formatRelative,
    localize: localize,
    match: match,
    options: {
        weekStartsOn: 0 /* Sunday */,
        firstWeekContainsDate: 1,
    },
};

export default locale;
