import { DoneFilled, InfoFilled, WarningTrFilled } from '@indriver/mireska';
import { FC, memo } from 'react';
import { EnumSnackType, ISnack, useSnacks } from 'shared/hooks/useSnacks';
import { SnackbarItem } from './snackbar.styles';

export interface ISnackProps {
    snack: ISnack;
}

export const SnackIcon = ({ type }: { type: EnumSnackType }) => {
    switch (type) {
        case EnumSnackType.success:
            return <DoneFilled color='var(--extensions-text-and-icon-alwayslight)' size={32} />;

        case EnumSnackType.failure:
            return <InfoFilled color='var(--extensions-text-and-icon-alwayslight)' size={32} />;

        case EnumSnackType.warning:
            return <WarningTrFilled color='var(--extensions-text-and-icon-onbrand)' size={32} />;

        case EnumSnackType.info:
        default:
            return <InfoFilled color='var(--extensions-text-and-icon-alwayslight)' size={32} />;
    }
};

export const SnackbarItemControl: FC<ISnackProps> = ({ snack }) => {
    const { removeSnack } = useSnacks();

    return (
        <SnackbarItem snack={snack} onClick={() => removeSnack(snack.id)}>
            <SnackIcon type={snack.type} />
            <span>{snack.message}</span>
        </SnackbarItem>
    );
};

export const SnackbarItemMemo = memo<ISnackProps>(SnackbarItemControl, (prev, next) => {
    return prev.snack.id !== next.snack.id;
});
