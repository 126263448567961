import getConfig from 'next/config';
import { apiInstance } from 'common/api/base';
import axios from 'axios';
import { getRefreshToken, removeAccessToken, setAccessToken } from 'common/lib/auth';
import { logger } from '@indriver/elastic-logger';
import Router from 'next/router';
import { IUser } from 'common/lib/parse-jwt';
import { getUrlWithNamespaceHash } from 'common/lib/get-url-with-namespace-hash';
import jose from 'node-jose';
import * as Sentry from '@sentry/nextjs';
import { canUseDom } from 'shared/lib/can-use-dom';
import { ICheckAuthCodeResponse, IRequestAuthCodeResponse } from './models';

const { publicRuntimeConfig } = getConfig();

const PRIVATE_PEM = publicRuntimeConfig?.privatePEM ?? '';
const CAS_BACKEND_HOST = publicRuntimeConfig?.ENABLE_DEV
    ? getUrlWithNamespaceHash(publicRuntimeConfig.CAS_BACKEND_HOST)
    : publicRuntimeConfig.CAS_BACKEND_HOST;

const CAS_BACKEND_RENEW_HOST = publicRuntimeConfig?.ENABLE_DEV
    ? getUrlWithNamespaceHash(publicRuntimeConfig.CAS_BACKEND_RENEW_HOST)
    : publicRuntimeConfig.CAS_BACKEND_RENEW_HOST;

async function getPrivateKey() {
    const key = await jose.JWK.asKey(PRIVATE_PEM, 'pem');

    return key.toJSON(true);
}

export const createSign = async (variables: { [key: string]: string | number }): Promise<string> => {
    const textEncoder = new TextEncoder();
    const data = textEncoder.encode(JSON.stringify(variables));

    const crypto = window.crypto;
    const privateJWK = await getPrivateKey();

    const privateKey = await crypto.subtle.importKey(
        'jwk',
        privateJWK,
        { name: 'RSASSA-PKCS1-v1_5', hash: 'SHA-256' },
        true,
        ['sign'],
    );

    const signature = await crypto.subtle.sign({ name: 'RSASSA-PKCS1-v1_5', hash: 'SHA-256' }, privateKey, data);

    return Buffer.from(signature).toString('base64');
};
export interface IRequestAuthCodeBody {
    aud: string;
    locale: string;
    phone: string;
    phoneCode: string;
    captcha: string;
}

export interface ICheckCodeBody {
    phone: string;
    authcode: number;
    format: string;
    locale: string;
}

export const postRequestAuthCode = async ({ captcha, ...data }: IRequestAuthCodeBody) => {
    const signature = await createSign({ ...data });

    const response = await axios.post<IRequestAuthCodeResponse>(`${CAS_BACKEND_HOST}/api/sms`, data, {
        headers: {
            'x-signature': signature,
            'x-captcha': captcha,
        },
    });
    return response.data;
};

export const postCheckAuthCode = async (data: ICheckCodeBody) => {
    const response = await axios.post<ICheckAuthCodeResponse>(`${CAS_BACKEND_HOST}/api/v2/checkauthcode`, data, {});
    return response.data;
};

export const postSyncInfo = async () => {
    try {
        const response = await apiInstance.post<ICheckAuthCodeResponse>('/api/v1/users/passengers/sync_info');
        return response.data;
    } catch (e) {
        return e;
    }
};
export const getUserInfo = async () => {
    try {
        const response = await apiInstance.get<IUser>('/api/v1/users/info');
        return response.data;
    } catch (e) {
        Sentry.captureException(e);

        logger.info({ e }, 'get-user-info');
        return null;
    }
};

// 150 - astana
// 4375 - rio de janeiro

export const updateUserInfo = async (body: { name?: string; city_id?: number }) => {
    const response = await apiInstance.post('/web/auth/v1/user/update', body);
    return response.data;
};

export const getCipherValue = async (value: string | number) => {
    const response = await apiInstance.post(
        `/api/get-cipher`,
        { value },
        {
            baseURL: '/',
        },
    );
    return response.data;
};

export const refreshToken = async () => {
    return axios
        .post<ICheckAuthCodeResponse>(`${CAS_BACKEND_RENEW_HOST}/api/auth/v1/token/renew`, {
            refresh_token: getRefreshToken(),
        })
        .then(async (tokenRefreshResponse) => {
            logger.info({ tokenRefreshResponse });
            setAccessToken({
                accessToken: tokenRefreshResponse.data.access_token,
                refreshToken: tokenRefreshResponse.data.refresh_token,
            });
            return Promise.resolve();
        })
        .catch(async (err) => {
            Sentry.captureException(err);
            if (err?.response?.status === 403 || err?.response?.status === 400) {
                canUseDom && (await Router.push('/'));
                removeAccessToken();
                await Router.push('/');
                logger.error('refresh ERROR', err);
            }

            return Promise.reject(err);
        });
};
