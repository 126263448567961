import jwtDecode from 'jwt-decode';

export type UserId = number;

export interface IUser {
    id: UserId;
    avatar: string;
    city_id?: number;
    country_id: number;
    phone: string;
    name: string;
    cpf_status: boolean;
}

export interface IAccessTokenData {
    exp: number;
    iat: number;
    jti: string;
    user: IUser;
}

export const parseJwt = (token: string): IAccessTokenData => jwtDecode<IAccessTokenData>(token);
