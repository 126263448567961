/* eslint-disable no-console */
import * as Sentry from '@sentry/nextjs';
import { canUseDom } from 'shared/lib/can-use-dom';

export const setItemToStorage = <T>(key: string, value: T): void => {
    if (!canUseDom) {
        return;
    }

    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        Sentry.captureException(error);
        console.warn(error);
    }
};

export const getItemFromStorage = <T>(key: string): T | undefined => {
    if (!canUseDom) {
        return undefined;
    }

    try {
        const item = window.localStorage.getItem(key);

        if (!item) {
            return undefined;
        }

        return JSON.parse(item);
    } catch (error) {
        Sentry.captureException(error);
        return undefined;
    }
};
export const removeItemFromStorage = (key: string): undefined => {
    if (!canUseDom) {
        return undefined;
    }

    try {
        window.localStorage.removeItem(key);
        return undefined;
    } catch (error) {
        Sentry.captureException(error);
        return undefined;
    }
};

export const setItemToSessionStorage = <T>(key: string, value: T): void => {
    if (!canUseDom) {
        return;
    }

    try {
        window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        Sentry.captureException(error);
        console.warn(error);
    }
};

export const getItemFromSessionStorage = <T>(key: string): T | undefined => {
    if (!canUseDom) {
        return undefined;
    }

    try {
        const item = window.sessionStorage.getItem(key);

        if (!item) {
            return undefined;
        }

        return JSON.parse(item);
    } catch (error) {
        Sentry.captureException(error);
        return undefined;
    }
};
