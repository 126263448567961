export interface ITollsObj {
    orderpage: boolean;
    afterclickfind: boolean;
    beforeсlickfind: boolean;
}

export const generateString = (obj: ITollsObj) => {
    if (!obj?.orderpage && !obj?.afterclickfind) {
        return 'control';
    }

    let result = '';

    if (obj?.orderpage) {
        result += result.length > 0 ? '_order' : 'order';
    }
    if (obj?.afterclickfind) {
        result += result.length > 0 ? '_after' : 'after';
    }

    if (obj?.beforeсlickfind) {
        result += result.length > 0 ? '_before' : 'before';
    }

    return result;
};
