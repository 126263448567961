import { getCookie, setCookie, deleteCookie, CookieValueTypes } from 'cookies-next';
import getConfig from 'next/config';
import { OptionsType } from 'cookies-next/src/types';
import { parseJwt } from './parse-jwt';
import { getDateFromTimestamp } from './date';

const cfg = getConfig();
export interface IAuthByCodeFields {
    code: string;
    phoneNumber: string;
}

export enum CookiesEnum {
    accessToken = '@indrive/intercity/accessToken',
    refreshToken = '@indrive/intercity/refreshToken',
    referrerPage = '@indrive/intercity/referrerPage',
    formFilter = '@indrive/intercity/formFilter',
}

export const AUTH_CONFIRM_CODE_LENGTH = 4;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setCrossDomainCookie = (key: CookiesEnum, data: any, options?: OptionsType) => {
    setCookie(key, data, {
        // httpOnly: true,
        path: '/',
        // domain: 'site1.localhost',
        domain: cfg?.publicRuntimeConfig?.ENABLE_DEV ? 'indrive.dev' : 'indrive.com',
        secure: true,
        ...options,
    });
};

export const getAccessToken = (): CookieValueTypes => getCookie(CookiesEnum.accessToken);
export const getRefreshToken = (): CookieValueTypes => getCookie(CookiesEnum.refreshToken);
export const setAccessToken = ({
    accessToken,
    refreshToken,
}: {
    accessToken?: string;
    refreshToken?: string;
}): void => {
    if (accessToken) {
        const { exp } = parseJwt(accessToken);
        setCrossDomainCookie(CookiesEnum.accessToken, accessToken, {
            expires: getDateFromTimestamp(exp),
            path: '/',
        });
    }
    if (refreshToken) {
        const { exp } = parseJwt(refreshToken);
        setCrossDomainCookie(CookiesEnum.refreshToken, refreshToken, {
            expires: getDateFromTimestamp(exp),
            path: '/',
        });
    }
};
export const removeAccessToken = (): void => {
    deleteCookie(CookiesEnum.accessToken);
    deleteCookie(CookiesEnum.refreshToken);
};

export const isAuthorizedUser = (): boolean => typeof getAccessToken() === 'string' && !!getAccessToken();

export const canRefreshToken = (): boolean =>
    !isAuthorizedUser() && typeof getRefreshToken() === 'string' && !!getRefreshToken();
