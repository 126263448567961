import type { Quarter } from '../../../../types';
import type { Localize, LocalizeFn } from '../../../types';
import buildLocalizeFn from '../../../_lib/buildLocalizeFn/index';

const eraValues = {
    narrow: ['B', 'A'] as const,
    abbreviated: ['BC', 'AD'] as const,
    wide: ['Before Christ', 'Anno Domini'] as const,
};

const quarterValues = {
    narrow: ['1', '2', '3', '4'] as const,
    abbreviated: ['Q1', 'Q2', 'Q3', 'Q4'] as const,
    wide: ['1st quarter', '2nd quarter', '3rd quarter', '4th quarter'] as const,
};

// Note: in English, the names of days of the week and months are capitalized.
// If you are making a new locale based on this one, check if the same is true for the language you're working on.
// Generally, formatted dates should look like they are in the middle of a sentence,
// e.g. in Spanish language the weekdays and months should be in the lowercase.
const monthValues = {
    narrow: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'] as const,
    abbreviated: [
        'جنوری',
        'فروری',
        'مارچ',
        'اپریل',
        'مئی',
        'جون',
        'جولائی',
        'اگست',
        'ستمبر',
        'اکتوبر',
        'نومبر',
        'دسمبر',
    ] as const,
    wide: [
        'جنوری',
        'فروری',
        'مارچ',
        'اپریل',
        'مئی',
        'جون',
        'جولائی',
        'اگست',
        'ستمبر',
        'اکتوبر',
        'نومبر',
        'دسمبر',
    ] as const,
};

const dayValues = {
    narrow: ['S', 'M', 'T', 'W', 'T', 'F', 'S'] as const,
    short: ['اتوار', 'پیر', 'منگل', 'بدھ', 'جمعرات', 'جمعہ', 'ہفتہ'] as const,
    abbreviated: ['اتوار', 'پیر', 'منگل', 'بدھ', 'جمعرات', 'جمعہ', 'ہفتہ'] as const,
    wide: ['اتوار', 'پیر', 'منگل', 'بدھ', 'جمعرات', 'جمعہ', 'ہفتہ'] as const,
};

const dayPeriodValues = {
    narrow: {
        am: 'a',
        pm: 'p',
        midnight: 'mi',
        noon: 'n',
        morning: 'morning',
        afternoon: 'afternoon',
        evening: 'evening',
        night: 'night',
    },
    abbreviated: {
        am: 'AM',
        pm: 'PM',
        midnight: 'midnight',
        noon: 'noon',
        morning: 'morning',
        afternoon: 'afternoon',
        evening: 'evening',
        night: 'night',
    },
    wide: {
        am: 'a.m.',
        pm: 'p.m.',
        midnight: 'midnight',
        noon: 'noon',
        morning: 'morning',
        afternoon: 'afternoon',
        evening: 'evening',
        night: 'night',
    },
};

const formattingDayPeriodValues = {
    narrow: {
        am: 'a',
        pm: 'p',
        midnight: 'mi',
        noon: 'n',
        morning: 'in the morning',
        afternoon: 'in the afternoon',
        evening: 'in the evening',
        night: 'at night',
    },
    abbreviated: {
        am: 'AM',
        pm: 'PM',
        midnight: 'midnight',
        noon: 'noon',
        morning: 'in the morning',
        afternoon: 'in the afternoon',
        evening: 'in the evening',
        night: 'at night',
    },
    wide: {
        am: 'a.m.',
        pm: 'p.m.',
        midnight: 'midnight',
        noon: 'noon',
        morning: 'in the morning',
        afternoon: 'in the afternoon',
        evening: 'in the evening',
        night: 'at night',
    },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ordinalNumber: LocalizeFn<number, undefined> = (dirtyNumber, _options) => {
    const number = Number(dirtyNumber);

    // If ordinal numbers depend on context, for example,
    // if they are different for different grammatical genders,
    // use `options.unit`.
    //
    // `unit` can be 'year', 'quarter', 'month', 'week', 'date', 'dayOfYear',
    // 'day', 'hour', 'minute', 'second'.

    const rem100 = number % 100;
    if (rem100 > 20 || rem100 < 10) {
        switch (rem100 % 10) {
            case 1:
                return `${number}st`;
            case 2:
                return `${number}nd`;
            case 3:
                return `${number}rd`;
        }
    }

    return `${number}th`;
};

const localize: Localize = {
    ordinalNumber,

    era: buildLocalizeFn({
        values: eraValues,
        defaultWidth: 'wide',
    }),

    quarter: buildLocalizeFn({
        values: quarterValues,
        defaultWidth: 'wide',
        argumentCallback: (quarter) => (quarter - 1) as Quarter,
    }),

    month: buildLocalizeFn({
        values: monthValues,
        defaultWidth: 'wide',
    }),

    day: buildLocalizeFn({
        values: dayValues,
        defaultWidth: 'wide',
    }),

    dayPeriod: buildLocalizeFn({
        values: dayPeriodValues,
        defaultWidth: 'wide',
        formattingValues: formattingDayPeriodValues,
        defaultFormattingWidth: 'wide',
    }),
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default localize;
