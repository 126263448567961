/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getAccessToken, getRefreshToken, removeAccessToken, setAccessToken } from 'common/lib/auth';
import getConfig from 'next/config';
import { i18n } from 'next-i18next';
import { getItemFromStorage } from 'shared/lib/storage';
import Router from 'next/router';
import { getCookie } from 'cookies-next';
import { logger } from '@indriver/elastic-logger';
import { getUrlWithNamespaceHash } from 'common/lib/get-url-with-namespace-hash';
import * as Sentry from '@sentry/nextjs';
import { canUseDom } from 'shared/lib/can-use-dom';
import { ICheckAuthCodeResponse } from './models';

const { publicRuntimeConfig = {} } = getConfig();
const CAS_BACKEND_RENEW_HOST = publicRuntimeConfig?.ENABLE_DEV
    ? getUrlWithNamespaceHash(publicRuntimeConfig.CAS_BACKEND_RENEW_HOST)
    : publicRuntimeConfig.CAS_BACKEND_RENEW_HOST;
export const apiInstance = axios.create({
    baseURL: publicRuntimeConfig?.ENABLE_DEV
        ? getUrlWithNamespaceHash(publicRuntimeConfig.NEXT_PUBLIC_HOST_API ?? '')
        : publicRuntimeConfig.NEXT_PUBLIC_HOST_API ?? '',
    headers: {
        'Content-Type': 'application/json',
        'X-App': `web ${publicRuntimeConfig.APP_VERSION ?? '1.0.0'}`,
        'X-GA': '',
    },
});

apiInstance.interceptors.request.use(
    async (config) => {
        const gaCookie = getCookie('_ga');
        if (gaCookie && config?.headers) {
            config.headers['X-GA'] = gaCookie;
        }
        const accessToken = getAccessToken() as string;
        const cityFromId = (getItemFromStorage('X-City-Id') as string) || 1;
        if (accessToken && config?.headers) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        if (cityFromId && config?.headers) {
            config.headers['X-City-Id'] = cityFromId;
        }

        if (config.headers) {
            config.headers['Accept-Language'] = i18n?.language.slice(0, 2) ?? 'en';
        }

        return config;
    },
    async (err) => {
        return Promise.reject(err);
    },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const refreshAuthLogic = async (failedRequest: any) => {
    return axios
        .post<ICheckAuthCodeResponse>(`${CAS_BACKEND_RENEW_HOST}/api/auth/v1/token/renew`, {
            // .post<ICheckAuthCodeResponse>(`/api/auth/v1/token/renew`, {
            // .post<ICheckAuthCodeResponse>(`/api/renew-token`, {
            refresh_token: getRefreshToken(),
        })
        .then(async (tokenRefreshResponse) => {
            logger.info({ tokenRefreshResponse });
            setAccessToken({
                accessToken: tokenRefreshResponse.data.access_token,
                refreshToken: tokenRefreshResponse.data.refresh_token,
            });
            failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.access_token}`;
            return Promise.resolve();
        })
        .catch(async (err) => {
            Sentry.captureException(err);
            if (err?.response?.status === 403 || err?.response?.status === 400) {
                canUseDom && (await Router.push('/'));
                // await router.push('/');
                removeAccessToken();
                logger.error('refresh ERROR', err);
            }

            return Promise.reject(err);
        });
};

createAuthRefreshInterceptor(apiInstance, refreshAuthLogic, {
    pauseInstanceWhileRefreshing: false,
});
