import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { IUser } from 'common/lib/parse-jwt';
import { logger } from '@indriver/elastic-logger';
import { getUserInfo } from 'common/api/user';
import { canRefreshToken, isAuthorizedUser } from 'common/lib/auth';
import { getItemFromStorage } from 'shared/lib/storage';
import * as Sentry from '@sentry/nextjs';

interface IUserHookResult {
    userInfo: IUser | null;
    requestUserInfo: () => Promise<IUser | null>;
}

const UserContext = createContext<IUserHookResult | null>(null);

interface IUserContextProps {
    children: ReactNode;
}

export const UserProvider: FC<IUserContextProps> = ({ children }) => {
    const [userInfo, setUserInfo] = useState<IUser | null>(null);

    const isUserAuth = canRefreshToken() || isAuthorizedUser();

    const requestUserInfo = async () => {
        try {
            const user = await getUserInfo();
            if (user) {
                setUserInfo({ ...user, city_id: getItemFromStorage('X-City-Id') || undefined });
            }
            return user;
        } catch (e) {
            Sentry.captureException(e);
            logger.info({ e }, 'request_user_info');
            return null;
        }
    };

    useEffect(() => {
        (async () => {
            isUserAuth && (await requestUserInfo());
        })()
            .then((r) => r)
            .catch((e) => Sentry.captureException(e));
    }, [isUserAuth]);

    const userHookResult: IUserHookResult = { userInfo, requestUserInfo };
    return <UserContext.Provider value={userHookResult}>{children}</UserContext.Provider>;
};

export const useMe = (): IUserHookResult => {
    const context = useContext(UserContext);
    if (context === null) {
        throw new Error('useMe must be used within a UserProvider');
    }
    return context;
};
