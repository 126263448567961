import { i18n } from 'next-i18next';
import { NovaDictionary } from '@indriver/nova/dist/types/intl/types';

type TFunc = (lang: string, keys: string[]) => NovaDictionary;

const useTranslateComponents: TFunc = (lang, keys) => {
    return keys.reduce<NovaDictionary>((acc, key) => {
        acc[key] = i18n?.t(`components.DatePickerMobile.${key}`);
        return acc;
    }, {});
};

export default useTranslateComponents;
