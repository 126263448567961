/* eslint-disable */

import ru from 'date-fns/locale/ru';
import es from 'date-fns/locale/es';
import enIN from 'date-fns/locale/en-IN';
import hi from 'date-fns/locale/hi';
import enUS from 'date-fns/locale/en-US';
import arEG from 'date-fns/locale/ar-EG';
import fr from 'date-fns/locale/fr';
import getConfig from 'next/config';
import { useEffect, useState } from 'react';
import { urPK } from 'data/locales/locale';
import arMA from 'date-fns/locale/ar-MA';
import pt from 'date-fns/locale/pt';
import id from 'date-fns/locale/id';
import * as Sentry from '@sentry/nextjs';

interface ILocale {
    code?: string;
    formatDistance?: (...args: any[]) => any;
    formatRelative?: (...args: any[]) => any;
    localize?: {
        ordinalNumber: (...args: any[]) => any;
        era: (...args: any[]) => any;
        quarter: (...args: any[]) => any;
        month: (...args: any[]) => any;
        day: (...args: any[]) => any;
        dayPeriod: (...args: any[]) => any;
    };
    formatLong?: {
        date: (...args: any[]) => any;
        time: (...args: any[]) => any;
        dateTime: (...args: any[]) => any;
    };
    match?: {
        ordinalNumber: (...args: any[]) => any;
        era: (...args: any[]) => any;
        quarter: (...args: any[]) => any;
        month: (...args: any[]) => any;
        day: (...args: any[]) => any;
        dayPeriod: (...args: any[]) => any;
    };
    options?: {
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
        firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
    };
}

const { publicRuntimeConfig = {} } = getConfig();

const ENABLE_DEV = publicRuntimeConfig.ENABLE_DEV === 'true';

export const LANG: Record<string, ILocale> = {
    es: es,
    en: enIN,
    'en-US': enUS,
    hi: hi,
    ar: arEG,
    'ar-MA': arMA,
    ur: urPK,
    fr: fr,
    pt: pt,
    id: id,
};

// const LANG: Record<string, Promise<ILocale>> = {
//     es: import('date-fns/locale/es').then((module) => module.default),
//     en: import('date-fns/locale/en-IN').then((module) => module.default),
//     'en-US': import('date-fns/locale/en-US').then((module) => module.default),
//     hi: import('date-fns/locale/hi').then((module) => module.default),
//     ar: import('date-fns/locale/ar-EG').then((module) => module.default),
//     'ar-MA': import('date-fns/locale/ar-MA').then((module) => module.default),
//     ur: import('data/locales/locale').then((module) => module.urPK),
//     fr: import('date-fns/locale/fr').then((module) => module.default),
// };

if (ENABLE_DEV) {
    LANG.ru = ru;
    // LANG.ru = import('date-fns/locale/ru').then((module) => module.default);
}

// export const resolveLang = (locale: string) => LANG[locale];
export const resolveLang = async (locale: string): Promise<Locale> => {
    const langModule = await LANG[locale];
    return langModule;
};
export const useResolveLang = (language: string) => {
    const [locale, setLocale] = useState<Locale | undefined>(undefined);

    useEffect(() => {
        const fetchLocale = async () => {
            const resolvedLocale = await resolveLang(language);
            setLocale(resolvedLocale);
        };

        fetchLocale()
            .then((r) => r)
            .catch((e) => Sentry.captureException(e));
    }, [language]);

    return locale;
};
